/**
 * Sliders
 *
 * Gestion des sliders
 * bug on rezise see -> https://github.com/kenwheeler/slick/issues/542#issuecomment-168658956
 */
require('slick-carousel');
require('bootstrap');
import breakpoints from '../misc/breakpoints';

const carouselQsnSection = $('[data-role="carousel-qsn-section"]');
const carouselInspiSection = $('[data-role="carousel-inspi-section"]');
const carouselSingle = $('[data-role="carousel-single"]');
const carouselDistributeur = $('[data-role="carousel-distributeur"]');

let isInitMobile = false;
let isInitDesktop = false;
//mobile and desktop
let isInitCarousel = false;

initSlider();

function initSlider() {

  // mobile and dekstop
  if(!isInitCarousel) {

    carouselSingle.slick({
      slidesToShow: 1.05,
      mobileFirst: true,
      arrows: false,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive : [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            adaptiveHeight: true,
            dots: true,
            infinite: true,
            slidesToShow: 1,
          }
        }
      ]
    });

    carouselDistributeur.slick({
      slidesToShow: 1.10,
      mobileFirst: true,
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive : [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            infinite: true,
            arrows:true
          }
        }
      ]
    });


    isInitCarousel = true;
  }

  // carousel on mobile and not on desktop
  if (breakpoints.isMobileLarge() && !isInitMobile) {

    carouselQsnSection.slick({
      slidesToShow: 1.10,
      slidesToScroll: 1,
      arrows: false,
      mobileFirst: true,
      focusOnSelect: true,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true
    });

    carouselInspiSection.slick({
      slidesToShow: 1.2,
      slidesToScroll: 1,
      arrows: false,
      mobileFirst: true,
      focusOnSelect: true,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      slide: '.slide',
      rows: 0
    });

    isInitMobile = true;

  }

  else if (breakpoints.isDesktopLarge() && isInitMobile) {

    carouselQsnSection.slick('unslick');
    carouselInspiSection.slick('unslick');

    isInitMobile = false;
  }

}

// Window resize
window.addEventListener("resize", function () {
  initSlider();
});
